import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useEmployeeStoreState from "../EmployeeStoreState";
import useProducts from "./useProducts";
import { isNil } from "lodash";

const useFilters = () => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    availableFilters,
    loadingFilters,
    errorFilters,
    fetchFilters,
    selectedFilters,
    setCheckboxFilterSelection,
    setRangeFilterSelection,
    setSwitchFilterSelection,
    resetFilters,
    searchInput,
    setSearchInput,
    setSorting,
    sortBy,
    sortDir,
    resetFiltersButSwitch,
    loadingProducts,
  } = useEmployeeStoreState((state) => ({
    availableFilters: state.availableFilters,
    loadingFilters: state.loadingFilters,
    errorFilters: state.errorFilters,
    fetchFilters: state.fetchFilters,
    selectedFilters: state.selectedFilters,
    setCheckboxFilterSelection: state.setCheckboxFilterSelection,
    setRangeFilterSelection: state.setRangeFilterSelection,
    setSwitchFilterSelection: state.setSwitchFilterSelection,
    resetFilters: state.resetFilters,
    searchInput: state.searchInput,
    setSearchInput: state.setSearchInput,
    setSorting: state.setSorting,
    sortBy: state.sortBy,
    sortDir: state.sortDir,
    resetFiltersButSwitch: state.resetFiltersButSwitch,
    loadingProducts: state.loadingProducts,
  }));

  const location = useLocation();
  const history = useHistory();
  const { loadProducts, resetPagination } = useProducts();

  /**
   * Función para parsear los filtros desde la URL y establecer el estado en el store.
   */
  const parseFiltersFromURL = useCallback(() => {
    const params = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });

    // Objeto para almacenar temporalmente los valores de rango
    const rangeFilters = {};

    Object.keys(params).forEach((paramKey) => {
      if (paramKey === "search") {
        // Input de búsqueda
        const searchValue = params.search;
        if (typeof searchValue === "string") {
          setSearchInput(searchValue);
        }
      } else if (paramKey.endsWith("From") || paramKey.endsWith("To")) {
        // Filtro de tipo range
        const filterId = paramKey.replace(/(?:From|To)$/, ""); // Elimina 'From' o 'To' del final
        const isFrom = paramKey.endsWith("From");
        const value = params[paramKey];

        if (!rangeFilters[filterId]) {
          rangeFilters[filterId] = {};
        }

        const parsedValue =
          value !== undefined && value !== "" ? parseFloat(value) : undefined;

        if (!isNaN(parsedValue)) {
          rangeFilters[filterId][isFrom ? "min" : "max"] = parsedValue;
        }
      } else {
        // Asumimos que es un filtro de tipo checkbox
        const value = params[paramKey];
        if (Array.isArray(value)) {
          // Múltiples valores
          value.forEach((val) => {
            setCheckboxFilterSelection(paramKey, val.toString(), true);
          });
        } else if (typeof value === "string") {
          // Un solo valor
          setCheckboxFilterSelection(paramKey, value.toString(), true);
        }
      }
    });

    // Aplicar los filtros de rango
    Object.entries(rangeFilters).forEach(([filterId, range]) => {
      if (Object.keys(range).length > 0) {
        setRangeFilterSelection(filterId, range);
      }
    });

    // Manejar ordenamiento
    if (params.sortBy && params.sortDir) {
      setSorting(params.sortBy, params.sortDir);
    }
  }, [
    location.search,
    setCheckboxFilterSelection,
    setSearchInput,
    setRangeFilterSelection,
    setSorting,
  ]);

  /**
   * Función para serializar los filtros desde el store y actualizar la URL.
   */
  const updateURLWithFilters = useCallback(() => {
    const { selectedFilters, searchInput } = getSelectedFilters();
    const { sortBy, sortDir } = useEmployeeStoreState.getState();

    const params = {};

    // Serializar filtros de tipo checkbox
    if (
      selectedFilters.checkbox &&
      Object.keys(selectedFilters.checkbox).length > 0
    ) {
      for (const [filterId, values] of Object.entries(
        selectedFilters.checkbox
      )) {
        if (Array.isArray(values) && values.length > 0) {
          // Asegurarse de que todos los valores sean cadenas de texto
          params[filterId] = values.map((value) => value.toString());
        }
      }
    }

    // Serializar filtros de tipo range
    if (
      selectedFilters.range &&
      Object.keys(selectedFilters.range).length > 0
    ) {
      for (const [key, value] of Object.entries(selectedFilters.range)) {
        if (value !== undefined) {
          params[key] = value;
        }
      }
    }

    // Agregar el input de búsqueda
    if (searchInput.trim() !== "") {
      params.search = searchInput.trim();
    }

    // Agregar parámetros de ordenamiento
    if (sortBy && sortDir) {
      params.sortBy = sortBy;
      params.sortDir = sortDir;
    }

    const queryStr = queryString.stringify(params, { arrayFormat: "bracket" });

    history.replace({
      pathname: location.pathname,
      search: queryStr ? `?${queryStr}` : "",
    });
  }, [history, location.pathname]);

  /**
   * Función auxiliar para obtener los filtros seleccionados y el input de búsqueda.
   */
  const getSelectedFilters = () => {
    const state = useEmployeeStoreState.getState();
    return {
      selectedFilters: state.selectedFilters,
      searchInput: state.searchInput,
    };
  };

  /**
   * Efecto para parsear los filtros desde la URL cuando los filtros disponibles han sido cargados.
   */
  useEffect(() => {
    if (availableFilters.length > 0) {
      parseFiltersFromURL();
      setIsInitialLoad(false);
    }
  }, [availableFilters, parseFiltersFromURL]);

  /**
   * Efecto para actualizar la URL cuando los filtros cambian.
   */
  useEffect(() => {
    if (!isInitialLoad) {
      updateURLWithFilters();
    }
  }, [
    selectedFilters.checkbox,
    selectedFilters.range,
    searchInput,
    updateURLWithFilters,
    isInitialLoad,
    sortBy,
    sortDir,
  ]);

  const handleCheckboxFilterChange = useCallback(
    (filterId, optionId, isSelected) => {
      setCheckboxFilterSelection(filterId, optionId, isSelected);
      // reset pagiantion
      resetPagination();
      // Llamar a fetchProducts después de actualizar el estado de los filtros
      loadProducts();
    },
    [setCheckboxFilterSelection, loadProducts, resetPagination]
  );

  const handleRangeFilterChange = useCallback(
    (filterId, range) => {
      setRangeFilterSelection(filterId, range);
      // reset pagiantion
      resetPagination();
      // Llamar a fetchProducts después de actualizar el estado de los filtros
      loadProducts();
    },
    [setRangeFilterSelection, loadProducts, resetPagination]
  );

  const handleSwitchFilterChange = useCallback(
    (filterId, ckecked, cancelFetch) => {
      setSwitchFilterSelection(filterId, ckecked);
      // Reset pagiantion
      resetPagination();

      // Llamar a fetchProducts después de actualizar el estado de los filtros
      if (isNil(cancelFetch) || cancelFetch === false) {
        loadProducts();
      }
    },
    [loadProducts, resetPagination, setSwitchFilterSelection]
  );

  const handleResetFilters = useCallback(() => {
    resetFiltersButSwitch();
    resetPagination();
    loadProducts();
  }, [loadProducts, resetFiltersButSwitch, resetPagination]);

  const handleSearchInputChange = useCallback(
    (input) => {
      setSearchInput(input);
    },
    [setSearchInput]
  );

  const handleApplyFilters = useCallback(() => {
    loadProducts();
  }, [loadProducts]);

  const handleSorting = useCallback(
    (sortBy, sortDir) => {
      setSorting(sortBy, sortDir);
      loadProducts();
    },
    [setSorting, loadProducts]
  );

  const blockFilters = useMemo(() => {
    return loadingFilters === true || loadingProducts === true;
  }, [loadingFilters, loadingProducts]);

  return {
    availableFilters,
    loadingFilters,
    errorFilters,
    fetchFilters,
    selectedFilters,
    handleCheckboxFilterChange,
    handleRangeFilterChange,
    handleSwitchFilterChange,
    handleResetFilters,
    searchInput,
    handleSearchInputChange,
    handleApplyFilters,
    handleSorting,
    sortBy,
    sortDir,
    isInitialLoad,
    resetFilters,
    resetFiltersButSwitch,
    blockFilters
  };
};

export default useFilters;
