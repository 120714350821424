import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  List,
  ListItem,
  Typography,
  Button,
  Box,
  Icon,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import useEmployeeStoreState from "../store/EmployeeStoreState";
import ProductQuickViewModal from "../components/ProductQuickViewModal";
import { useTranslation } from "react-i18next";
import useFavorites from "../store/hooks/useFavorites";
import useProducts from "../store/hooks/useProducts";
import {
  find,
  isEmpty,
  isNil,
  //toNumber
} from "lodash";
import { utcDateToLocal } from "../../../util/UtilDates";
import {
  DATE_TIME_PATTERN,
  FRONT_DATE_TIME_PATTERN,
} from "../../../util/Constants";
import { format, parse } from "date-fns";
import ProductImage from "../components/ProductImage";
import ProductPriceAndStock from "../components/ProductPriceAndStock";
import ProductActions from "../components/ProductActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 1000,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 1200,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 1400,
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid #e0e0e0",
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginBottom: 6,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  listItemDisabled: {
    background:
      "repeating-linear-gradient(-45deg, #c6c6c687, #ffffff 5px, #ffffff 5px, #ebebeb96 25px)",
    borderRadius: "10px",
  },
  clickableInfo: {
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  productInfo: {
    flex: 1,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    //width: 250
  },
  button: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5, 1),
    fontSize: "0.75rem",
    width: "100%",
  },
  addToCartButton: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    border: `1px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFF",
    },
  },
  viewItemButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  deleteFavItemButton: {
    border: `1px solid ${theme.palette.semaphore.red}`,
    color: theme.palette.semaphore.red,
  },
  deleteIcon: {
    color: theme.palette.semaphore.red,
  },
  typoPrice: {
    fontWeight: 700,
  },
  itemNoAvailableBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
  },
  placeholderBox: {
    border: "1px solid",
    opacity: "0.5",
    padding: "32px",
  },
}));

const iconImagePlaceholder = {
  width: "auto",
  height: "auto",
  padding: 2,
  fontSize: 90,
};

function safeFormatting(dateAdded) {
  if (isNil(dateAdded)) {
    return null;
  }

  try {
    const formatted = format(
      utcDateToLocal(parse(dateAdded, DATE_TIME_PATTERN, new Date())),
      FRONT_DATE_TIME_PATTERN
    );

    return formatted;
  } catch (_) {
    return null;
  }
}

export default function EmployeeStoreFavsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedProduct = useEmployeeStoreState(
    (state) => state.selectedProduct
  );
  const openQuickView = useEmployeeStoreState((state) => state.openQuickView);
  const closeQuickView = useEmployeeStoreState((state) => state.closeQuickView);
  const { favorites, removeFavorite, getFavorites } = useFavorites();
  const { loadingProducts, products, loadProducts } = useProducts();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  useEffect(() => {
    if (isNil(products) || isEmpty(products)) {
      loadProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EmployeeStorePageWrapper>
      <div className={classes.root}>
        {loadingProducts ? (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List className={classes.list}>
            <Typography variant="h4">
              {`${t("EMPLOYEE_STORE.HEADER_BTN_FAVS")} (${favorites.length})`}
            </Typography>
            {/* TODO: Agregar estilo el empty state */}
            {favorites.length === 0 && <p>{t("EMPLOYEE_STORE.NO_FAVORITE_PRODUCTS")}</p>}

            {favorites.map((fav) => {
              const product = find(products, { sku: fav?.sku, ean: fav?.ean });
              const formattedAdded = safeFormatting(fav?.dateAdded);
              //const productHasPrice = toNumber(product?.price) > 0;
              //const productHasStock = toNumber(product?.stock) > 0;

              if (!isNil(product)) {
                return (
                  <ListItem key={product.sku} className={classes.listItem}>
                    <Box
                      display="flex"
                      alignItems={isSmallScreen ? "flex-start" : "center"}
                      flexDirection={isSmallScreen ? "column" : "row"}
                      flex={1}
                      onClick={() => openQuickView(product)}
                      role="button"
                      className={classes.clickableInfo}
                    >
                      <ProductImage
                        firm={product.firm}
                        imageUrl={product.imageUrl}
                        title={product.title}
                      />

                      <Box className={classes.productInfo}>
                        <Typography variant="h5">
                          {product.firmDescription}
                        </Typography>
                        <Typography variant="subtitle1">
                          {product.title}
                        </Typography>
                        <ProductPriceAndStock {...product} />
                        {!isNil(formattedAdded) ? (
                          <Typography variant="subtitle1">
                            {t("EMPLOYEE_STORE.ADDED")} {formattedAdded}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box className={classes.buttons}>
                      <ProductActions item={product} isShowedInFav={true} />
                      <Button
                        variant="outlined"
                        className={`${classes.button} ${classes.deleteFavItemButton}`}
                        startIcon={
                          <Icon
                            className={`fas fa-heart-broken ${classes.deleteIcon}`}
                          />
                        }
                        onClick={() => removeFavorite(product)}
                      >
                         {t("EMPLOYEE_STORE.RMV_FROM_FAVORITES")}
                      </Button>
                    </Box>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={fav.sku}
                    className={`${classes.listItem} ${classes.listItemDisabled}`}
                  >
                    <Box className={classes.itemNoAvailableBox}>
                      <Box className={classes.placeholderBox}>
                        <Icon
                          className="fas fa-image"
                          style={iconImagePlaceholder}
                        />
                      </Box>
                      <div>
                        <Typography variant="h5">
                        {t("EMPLOYEE_STORE.PRODUCT_NOT_AVAILABLE")}
                        </Typography>
                        <Typography variant="h6">EAN: {fav.ean}</Typography>
                        <Typography variant="subtitle1">
                        {t("EMPLOYEE_STORE.ADDED")} {formattedAdded}
                        </Typography>
                      </div>
                    </Box>
                    <Box className={classes.buttons}>
                      <Button
                        variant="outlined"
                        className={`${classes.button} ${classes.deleteFavItemButton}`}
                        startIcon={
                          <Icon
                            className={`fas fa-heart-broken ${classes.deleteIcon}`}
                          />
                        }
                        onClick={() =>
                          removeFavorite({ ean: fav.ean, sku: fav.sku })
                        }
                      >
                        {t("EMPLOYEE_STORE.RMV_FROM_FAVORITES")}
                      </Button>
                    </Box>
                  </ListItem>
                );
              }
            })}
          </List>
        )}
        <ProductQuickViewModal
          product={selectedProduct}
          open={Boolean(selectedProduct)}
          onClose={closeQuickView}
        />
      </div>
    </EmployeeStorePageWrapper>
  );
}
