import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EmployeeStorePageWrapper from "../layout/EmployeeStorePageWrapper";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Link,
  Typography,
} from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import ShoppingCartItem from "../components/shopping-cart/ShoppingCartItem";
import ShoppingCartOrderSummary from "../components/shopping-cart/ShoppingCartOrderSummary";

import useCart from "../store/hooks/useCart";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EMPLOYEE_STORE_HOME_ROUTE } from "../routes/EmployeeStoreRouter";
import useSimulation from "../store/hooks/useSimulation";
import useCheckout from "../store/hooks/useCheckout";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowY: "auto",
  },
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 30,
    width: "100%",
    height: "100%",
    padding: 16,
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  topActionsBox: {
    gap: "6px",
    display: "flex",
    padding: "8px",
    alignItems: "center",
    marginBottom: "8px",
    justifyContent: "flex-end",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "10px",
  },
}));

const emptyCartIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  fontSize: 120,
};

export default function EmployeeStoreShoppingCartPage() {
  const classes = useStyles();
  const { loadingCart, cartItems, handleClearCart } = useCart();
  const { showSimulationDetails, toggleSimulationDetails, isSimulating } =
    useSimulation();
  const { checkoutStep } = useCheckout();
  let history = useHistory();
  const { t } = useTranslation();

  const disableClearCartItems = isSimulating || checkoutStep > 0;

  return (
    <EmployeeStorePageWrapper>
      {loadingCart ? (
        <div className={classes.emptyContainer}>
          <CircularProgress size={72} />
        </div>
      ) : isEmpty(cartItems) ? (
        <div className={classes.emptyContainer}>
          <Icon className="fas fa-cart-plus" style={emptyCartIcon} />
          <Typography variant="h4" align="center">
            ¡Aún no tienes nada en tu carrito! Añade{" "}
            <Link
              onClick={() => history.push(EMPLOYEE_STORE_HOME_ROUTE)}
              className={classes.link}
            >
              productos
            </Link>{" "}
            para verlos aquí
          </Typography>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box className={classes.topActionsBox}>
                {checkoutStep > 0 ? (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={
                      showSimulationDetails ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    onClick={toggleSimulationDetails}
                  >
                    {showSimulationDetails
                      ? "Ocultar detalles simulación"
                      : "Mostrar detalles simulación"}
                  </Button>
                ) : null}

                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<DeleteSweepIcon />}
                  onClick={handleClearCart}
                  disabled={disableClearCartItems}
                >
                  {t("EMPLOYEE_STORE.EMPTYING_CART")} 
                </Button>
              </Box>
              {cartItems.map((item) => (
                <ShoppingCartItem key={item.sku} {...item} />
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <ShoppingCartOrderSummary />
            </Grid>
          </Grid>
        </div>
      )}
    </EmployeeStorePageWrapper>
  );
}
