import React, { useEffect } from "react";
import { Box, Switch, Typography, makeStyles } from "@material-ui/core";
import useFilters from "../../store/hooks/useFilters";
import FilterAdminBadge from "./FilterAdminBadge";
import useInfo from "../../store/hooks/useInfo";
import { find, has, isEmpty, isNil } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  itemsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 6,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  btn: {
    border: "1px solid",
  },
}));

const FilterSwitch = ({ filter }) => {
  const classes = useStyles();

  const {
    availableFilters,
    selectedFilters,
    handleSwitchFilterChange,
    blockFilters,
  } = useFilters();
  const { initialInfo } = useInfo();

  const filterId = filter?.id;
  const checked =
    !isNil(selectedFilters) &&
    !isNil(selectedFilters.switch) &&
    !isEmpty(selectedFilters.switch) &&
    selectedFilters?.switch?.[filterId] === true;

  const showOnlyAdmin = filter?.showOnlyAdmin === true;
  const isEmsAdmin = initialInfo?.isEmsAdmin === true;

  const handleChange = (event) => {
    const newState = event.target.checked;
    handleSwitchFilterChange(filter.id, newState);
  };

  useEffect(() => {
    if (
      find(availableFilters, { id: filterId })?.defaultValue === true &&
      !has(selectedFilters?.switch, filterId)
    ) {
      handleSwitchFilterChange(filter.id, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isEmsAdmin && showOnlyAdmin) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.itemsContainer}>
        <Typography variant="h6" className={classes.title}>
          {filter?.name}
        </Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          name={filter?.name}
          inputProps={{ "aria-label": `${filter?.name} switch` }}
          disabled={blockFilters}
        />
      </Box>
      <FilterAdminBadge {...filter} />
    </Box>
  );
};

export default FilterSwitch;
