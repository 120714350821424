import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import useEmployeeStoreState from "../EmployeeStoreState";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useInfo = () => {
  const { t } = useTranslation();
  const headers = useRequestHeaders();
  const { logout } = useContext(AuthContext);
  let history = useHistory();

  const {
    loadingInfo,
    errorLoadingInfo,
    initialInfo,
    fetchInitialInfo,
    firstInitialInfoCompleted,
    showClosedEmsDialog,
    openClosedEmsDialog,
    closeClosedEmsDialog,
  } = useEmployeeStoreState((state) => ({
    loadingInfo: state.loadingInfo,
    errorLoadingInfo: state.errorLoadingInfo,
    initialInfo: state.initialInfo,
    fetchInitialInfo: state.fetchInitialInfo,
    firstInitialInfoCompleted: state.firstInitialInfoCompleted,
    showClosedEmsDialog: state.showClosedEmsDialog,
    openClosedEmsDialog: state.openClosedEmsDialog,
    closeClosedEmsDialog: state.closeClosedEmsDialog,
  }));

  const handleFetchInitialInfo = useCallback(() => {
    fetchInitialInfo(headers, logout, t);
  }, [fetchInitialInfo, headers, logout, t]);

  const handleCloseDialog = useCallback(() => {
    if (initialInfo?.isEmsAdmin === true) {
      history.push("/");
    } else {
      logout(false);
    }
  }, [logout, initialInfo, history]);

  return {
    handleFetchInitialInfo,
    loadingInfo,
    errorLoadingInfo,
    initialInfo,
    firstInitialInfoCompleted,
    handleCloseDialog,
    showClosedEmsDialog,
    openClosedEmsDialog,
    closeClosedEmsDialog,
  };
};

export default useInfo;
